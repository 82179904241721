import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'

import NavigationActionList from './navigation-action-list'

const NavigationLink = styled(Link)`
  color: #000;
  &:hover {
    color: #2971f4;
  }
`

const ExternalNavigationLink = NavigationLink.withComponent(`a`)

export default ({ item }) => {
  const {
    name,
    internal: { type },
  } = item
  if (type === 'ContentfulApiGuide' || type === 'ContentfulApiResource') {
    const { uniqueIdentifier, sections, api_action } = item
    return (
      <li>
        <NavigationLink
          className="nav-section-list-item__link"
          to={`/#${uniqueIdentifier}`}
        >
          <span
            dangerouslySetInnerHTML={{
              __html: name.replace(
                /`(\S+)`/g,
                (full, match) => `<pre>${match}</pre>`
              ),
            }}
          />
        </NavigationLink>
        {sections ? <NavigationActionList actions={sections} /> : null}
        {api_action ? <NavigationActionList actions={api_action} /> : null}
      </li>
    )
  } else if (type === 'ContentfulApiLink') {
    const { isExternal, url, name } = item
    return (
      <li>
        {isExternal ? (
          <ExternalNavigationLink href={url} target="_blank">
            {name}
          </ExternalNavigationLink>
        ) : (
          <NavigationLink to={url}>{name}</NavigationLink>
        )}
      </li>
    )
  }
}
