import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import styled from '@emotion/styled'
import { useStaticQuery, graphql } from 'gatsby'

import Navigation from './navigation'

import './layout.css'

const UnsupportedViewPortOverlay = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  background: #333;
  z-index: 10000;
  display: grid;
  padding: 0 6vw;
  > h1 {
    margin: auto;
    text-align: center;
    color: #fff;
  }
  @media (min-width: 1200px) {
    display: none;
  }
`

const Layout = React.memo(({ children }) => {
  const data = useStaticQuery(graphql`
    query LayoutQuery {
      site: site {
        siteMetadata {
          title
          description
        }
      }
    }
  `)

  return (
    <>
      <Helmet>
        <title>
          {data.site.siteMetadata.title} - {data.site.siteMetadata.description}
        </title>
      </Helmet>
      <UnsupportedViewPortOverlay>
        <h1>
          Our Developer Documentation isn’t optimised for small screens yet.
          Please visit this site from a bigger device.
        </h1>
      </UnsupportedViewPortOverlay>
      <Navigation />
      {children}
    </>
  )
})

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
