import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'

const NavigationActionList = styled.ul`
  list-style: none;
  margin-left: 0;
`

const NavigationActionLink = styled(Link)`
  padding: 10px;
  padding-left: 20px;
  border-left: 2px solid #eaecf0;
  &:hover {
    border-color: #2971f4;
  }
  &:hover > a {
    color: #2971f4;
  }
  color: #797a7c;
  &:hover {
    color: #2971f4;
  }
`

export default ({ actions }) => (
  <NavigationActionList className="nav-action-list">
    {actions.map(({ id, name, uniqueIdentifier }) => (
      <li className="nav-action-list__item" key={id}>
        <NavigationActionLink
          className="nav-action-list__link"
          to={`/#${uniqueIdentifier}`}
        >
          <span
            dangerouslySetInnerHTML={{
              __html: name.replace(
                /`(\S+)`/g,
                (full, match) => `<pre>${match}</pre>`
              ),
            }}
          />
        </NavigationActionLink>
      </li>
    ))}
  </NavigationActionList>
)
