/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from 'react'
import Layout from './src/components/layout'

export const onServiceWorkerUpdateFound = () => {
  const answer = window.confirm(
    `Impala Docs have been updated. Reload to display the latest version?`
  )

  if (answer === true) {
    window.location.reload()
  }
}

export const onRouteUpdate = ({ location }) => {
  if (location.hash) {
    document.querySelector(location.hash).scrollIntoView({ behavior: 'smooth' })
  }
}

export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>
}
