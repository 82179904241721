import React from 'react'
import styled from '@emotion/styled'
import { useStaticQuery, graphql } from 'gatsby'

import NavigationSection from './navigation-section'
import LogoImg from './logo.svg'

const Navigation = styled.nav`
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  overflow: scroll;
  background: #f4f6f9;
  padding-left: 60px;
  padding-top: 40px;
  z-index: 999;
  @media print {
    display: none;
  }
`

const Logo = styled.a`
  & > img {
    width: 132px;
  }
`

export default () => {
  const data = useStaticQuery(graphql`
    query NavigationQuery {
      allContentfulApiDocs {
        edges {
          node {
            sections {
              id
              name
              content {
                ... on ContentfulApiGuide {
                  id
                  name
                  uniqueIdentifier
                  internal {
                    type
                  }
                  sections {
                    ... on ContentfulApiGuide {
                      id
                      name
                      uniqueIdentifier
                    }
                  }
                }
                ... on ContentfulApiResource {
                  id
                  name
                  uniqueIdentifier
                  internal {
                    type
                  }
                  api_action {
                    id
                    name
                    uniqueIdentifier
                  }
                }
                ... on ContentfulApiLink {
                  id
                  name
                  url
                  isExternal
                  internal {
                    type
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const {
    sections: navigationSections,
  } = data.allContentfulApiDocs.edges[0].node

  return (
    <Navigation>
      <Logo href="https://getimpala.com/">
        <img src={LogoImg} alt="impala-logo" />
      </Logo>
      {navigationSections.map(({ id, name, content }) => (
        <NavigationSection key={id} name={name} items={content} />
      ))}
    </Navigation>
  )
}
