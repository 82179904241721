import React from 'react'
import styled from '@emotion/styled'

import NavSectionListItem from './navigation-section-list-item'

const NavSection = styled.div`
  margin-bottom: 4rem;
`

const NavSectionHeader = styled.h5`
  text-transform: uppercase;
  font-weight: 400;
  opacity: 0.4;
  margin-bottom: 20px;
`

const NavSectionList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 15px;
`

export default React.memo(({ name, items }) => (
  <NavSection className="nav-section">
    <NavSectionHeader className="nav-section__header">{name}</NavSectionHeader>
    <NavSectionList className="nav-section__list">
      {items.map(item => (
        <NavSectionListItem key={item.id} item={item} />
      ))}
    </NavSectionList>
  </NavSection>
))
